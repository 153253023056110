import 'lazysizes';
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'

window.Alpine = Alpine

Alpine.plugin(intersect)
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.start()


/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}